import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["state-dropdown-frame"]
  static values = { stateIds: Array }
  
  connect() {
    this.stateIdsValue = this.frameTarget.dataset.stateIds.split(",").map(Number)
  }

  update() {
    const selectedStateIds = this.stateIdsValue
    const frame = this.frameTarget

    frame.setAttribute("data-state-ids", selectedStateIds.join(","))
    frame.innerHTML = ""
  }

  handleChange(event) {
    console.log("select changed")
    this.element.requestSubmit()
  }
}