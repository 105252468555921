// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"

// import "./theme/theme"
import "./theme/theme"

import "jquery";
import "ion-rangeslider";

// Make jQuery available globally
window.$ = window.jQuery = require("jquery");

addEventListener("turbo:before-frame-render", (event) => {
  console.log("turbo:before-frame-render");
  if (document.startViewTransition) {
    const originalRender = event.detail.render;
    event.detail.render = (currentElement, newElement) => {
      document.startViewTransition(() =>
        originalRender(currentElement, newElement)
      );
    };
  } else {
    console.log("no startViewTransition");
  }
});

