import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto'
import { ChoroplethController, GeoFeature, ColorScale, ProjectionScale } from 'chartjs-chart-geo';
import * as ChartGeo from 'chartjs-chart-geo';

// register controller in chart.js and ensure the defaults are set
Chart.register(ChoroplethController, GeoFeature, ColorScale, ProjectionScale);


export default class UsStatesChartController extends Controller {

  connect() {
    console.log("UsStatesChartController#connect")
    let elem = document.getElementById('usStatesChart')
    if(Chart.getChart(elem)) {
      console.log("Chart already exists")
      return
    }

    console.log(this.statEle)
    console.log(this.dataSet)
    console.log(this.stats)
    console.log(Object.keys(this.stats))
    console.log(Object.values(this.stats))

    let total = Object.values(this.stats)[0]
    console.log(total)

    var labels = Object.keys(this.stats)
    var values = Object.values(this.stats)

    labels = labels.map(this.humanize)

    console.log(labels)
    console.log(values)

    this.render()
  }

  render(){
    fetch('https://unpkg.com/us-atlas/states-10m.json').then((r) => r.json()).then((us) => {
      const nation = ChartGeo.topojson.feature(us, us.objects.nation).features[0];
      const states = ChartGeo.topojson.feature(us, us.objects.states).features;

      console.log(us.objects.states)

      const chart = new Chart(this.element.getContext("2d"), {
        type: 'choropleth',
        data: {
          labels: states.map((d) => d.properties.name),
          datasets: [{
            label: 'States',
            outline: nation,
            data: states.map((d) => ({feature: d, value: this.stats[d.properties.name]})),
          }]
        },
        options: {
          plugins: {
            legend: {
              display: false
            },
          },
          scales: {
            projection: {
              axis: 'x',
              projection: 'albersUsa'  
            },
            color: {
              axis: 'x',
              quantize: 5,
              legend: {
                position: 'bottom-right',
                align: 'bottom'
              },
            }
          },
        }
      });
    });
  }

  humanize(str) {
    return str
        .replace(/^[\s_]+|[\s_]+$/g, '')
        .replace(/[_\s]+/g, ' ')
        .replace(/^[a-z]/, function(m) { return m.toUpperCase() })
  }

  get dataSet() {
    return this._dataSet = this._dataSet || this.statEle.dataset
  }

  get stats() {
    return this._getStats = this._getStats || JSON.parse(this.dataSet.stateCounts)
  }

  get statEle() {
    return this._ele = this._ele || document.getElementById(this.element.dataset.statId)
  }
}