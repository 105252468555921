import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 
    "fieldSelect",
    "opertorSelect",
    "secondValue",
    "fieldClick",
    "operatorClick"
  ]
  connect() {
    console.log(this.fieldSelectTarget.dataset.fieldTypes)
  }

  updateField(event) {
    this.fieldClickTargets.forEach(target => target.click())
  }
  
  updateOperator(event) {
    this.operatorClickTargets.forEach(target => target.click())
  }
}
