import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("ZipcodesController connected")
    console.log(this.element.id)
  }

  handleChange(event) {
    console.log("select changed")
    //this.element.requestSubmit()
  }
}