import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto';

export default class DonutChartController extends Controller {

  connect() {
    console.log("DonutChartController#connect")
    let elem = document.getElementById("trafficChart");
    if(Chart.getChart(elem)) {
      console.log("Chart already exists")
      return
    }

    this.render()

    const chartEvent = new CustomEvent("blue:update-chart")
    document.dispatchEvent(chartEvent)
  }

  render(){
    console.log("DonutChartController#render")

    new Chart(document.getElementById("trafficChart"), {
      type: "bar",
      data: {
        labels: this.labels,
        datasets: [
          {
            label: 'Vistors by Age',
            data: this.stats,
            backgroundColor: ["#2C7BE5", "#A6C5F7", "#D2DDEC"],
          },
        ],
      },
    });
  }

  humanize(str) {
    return str
        .replace(/^[\s_]+|[\s_]+$/g, '')
        .replace(/[_\s]+/g, ' ')
        .replace(/^[a-z]/, function(m) { return m.toUpperCase() })
  }

  get stats() {
    return JSON.parse(this.element.dataset.results)
  }

  get labels(){
    return JSON.parse(this.element.dataset.labels);
  }

}